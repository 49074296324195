import { get, post } from '@/utils/axios';

interface APIProps {
  getInfo: (params: any) => Promise<any>
  sendBindCode: (params: any) => Promise<any>
  confirmBinding: (params: any) => Promise<any>
  getCardList: (params: any) => Promise<any>
  authCard: (params: any) => Promise<any>
}
const API: APIProps = {
  // 获取用户信息
  getInfo: (params: any) => post('/hasee/bifrost/noLoginSignCard/getAuthInfo', params),
  // 发送绑卡短信（度小满）
  sendBindCode: (params: any) => post('/hasee/bifrost/noLoginSignCard/send', params),
  // 确认绑卡（度小满）
  confirmBinding: (params: any) => post('/hasee/bifrost/noLoginSignCard/sign', params),
  // 获取卡列表
  getCardList: (params: any) => post('/hasee/bifrost/noLoginSignCard/getCardList', params),
  // 授权绑卡
  authCard: (params: any) => post('/hasee/bifrost/noLoginSignCard/authCard', params),
};
export default API;
