import Axios, { AxiosInstance } from 'axios';
import { Toast } from 'vant';
import CryptoJS from 'crypto-js';
import { getToken, getStorage } from './auth';
import store from '@/store';
import router from '@/router';
import { DefaultConfig, DEFAULT_COMPILE_ENV } from '../config/env';

const { COMPILE_ENV = DEFAULT_COMPILE_ENV } = process.env; // dev test prod
const baseURL = DefaultConfig[COMPILE_ENV].baseUrl;
// const token = getToken();
// const newToken = token ? 'Bearer 34e81075-d94f-420f-882a-9b21ff3c8525' : '';
const axios: AxiosInstance = Axios.create({
  headers: {
    'x-timestamp': new Date().getTime().toString(),
    'x-version': '1.0.0',
    Authorization: '',
    'x-sign': '233',
    'x-client-id': 'resource-server',
    'x-tenant-header': '10000',
  },
  baseURL,
  timeout: 20000, // 请求超时 20s
});

// 前置拦截器（发起请求之前的拦截）
axios.interceptors.request.use(
  (config) => {
    const {
      method, data, params, headers = {},
    } = config;
    const nowDate = new Date().getTime().toString();
    const param = method === 'get' ? { ...params } : { ...data };
    const token = getToken();
    const newToken = token ? `Bearer ${token}` : '';

    // console.log(config, 1000);
    // const arr = ['x-timestamp', 'x-version', 'Authorization', 'x-client-id'];
    const headerStr = `x-timestamp:${nowDate}x-version:${headers['x-version']}Authorization:${newToken}x-client-id:${headers['x-client-id']}x-tenant-header:${headers['x-tenant-header']}`;
    // if (method === 'get') {
    //   headerStr += 'content-type:application/x-www-form-urlencoded';
    // }
    let secretKey = 'sl123qwe';
    const traceId: any = getToken();
    if (traceId) {
      secretKey = traceId.substring(5, 30);
    }
    let paramJson = method === 'get' && JSON.stringify(param) === '{}' ? '' : JSON.stringify(param);
    if (method === 'get' && paramJson) {
      const keyList = Object.keys(param);
      keyList.sort();
      paramJson = keyList.reduce((pur: any, key: string) => {
        const str = `${pur}${key}:${param[key]}`;
        return str;
      }, '');
    } else if (config.url === '/hasee/common/upload/oss') {
      paramJson = 'childPath:emblemuploadPath:CERT';
    } else if (config.url === '/hasee/nokia/member/uploadAvatar') {
      paramJson = 'fileType:IMAGE';
    } else if (config.url === '/hasee/common/upload/video') {
      paramJson = 'uploadPath:user-form';
    }
    const signPre = encodeURIComponent(
      CryptoJS.SHA256(`${paramJson}${headerStr}${secretKey}`) as any,
    ).toLocaleLowerCase();
    // console.log(`${paramJson}${headerStr}${secretKey}`, signPre, 999);
    const xSign = encodeURIComponent(
      CryptoJS.HmacSHA256(signPre, secretKey) as any,
    ).toLocaleLowerCase();
    // console.log(xSign, 101010);
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      'x-sign': xSign,
      'x-timestamp': nowDate,
      Authorization: newToken as any,
    };
    if (method === 'get') {
      config.data = true;
      config.headers['content-type'] = 'application/x-www-form-urlencoded';
    }
    // console.log(config.headers);
    // store.dispatch('setLoading', true);
    return config;
  },
  (error) => {
    setTimeout(() => {
      // store.dispatch('setLoading', 0);
    }, 300);
    return Promise.reject(error);
  },
);

// 后置拦截器（获取到响应时的拦截）
axios.interceptors.response.use(
  (response) => {
    // console.log(response, 999);
    // store.dispatch('setLoading', false);
    if (response.status === 200 && response.data.code === '200') {
      if (response.config.url === '/hasee/bifrost/signCard/sign') {
        return Promise.resolve(response);
      }
      return Promise.resolve(response.data);
    }
    const { data } = response;
    if (response.config.url === '/hasee/auth/mobileCodeLogin') {
      Toast.clear();
    }
    switch (data.code) {
      // 401: 未登录
      // 未登录则跳转登录页面，并携带当前页面的路径
      // 在登录成功后返回当前页面，这一步需要在登录页操作。
      case '401':
      case '403':
        Toast({
          message: '未登录或登录已过期',
          duration: 1000,
          forbidClick: true,
        });
        // console.log(12233, router, router.currentRoute.value);
        if (router.currentRoute.value.path !== '/login') {
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.value.fullPath,
            },
          });
        }
        break;
      // 登录次数超过5次需要图片验证码
      case '2100504':
        store.dispatch('setImgCode', true);
        localStorage.setItem('setImgCode', JSON.stringify({
          show: true,
          time: new Date().getTime(),
        }));
        Toast({
          message: data.message || '请输入图形验证码',
          duration: 1500,
          forbidClick: true,
        });
        break;
      // 404请求不存在
      case '404':
        Toast({
          message: '网络请求不存在',
          duration: 1500,
          forbidClick: true,
        });
        break;
      // 其他错误，直接抛出错误提示
      default:
        Toast({
          message: data.message || '系统错误',
          duration: 1500,
          forbidClick: true,
        });
    }
    if (response.config.url === '/hasee/bifrost/signCard/sign') {
      return Promise.resolve(response);
    }
    // console.log(response, 1010110);
    return Promise.reject(response.data);
  },
  (error) => {
    // store.dispatch('setLoading', false);
    if (error.response) {
      Toast({
        message: error.response.data.message || '系统错误',
        duration: 1500,
        forbidClick: true,
      });
    }
    return Promise.reject(error.response);
  },
);
export function get(url: string, params: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err.data);
    });
  });
}
export function post(url: string, params: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export default axios;
