const DEFAULT_COMPILE_ENV = 'prod';
// 域名
const DefaultConfig: any = {
  dev: {
    baseUrl: '/dev',
    publicUrl: '/',
  },
  prod: {
    baseUrl: 'https://hasee.binfenquzu.com',
    bucket: 'zjs-h5-prod',
    callbackUrl: 'https://h5pt.binfenquzu.com',
  },
};
export {
  DefaultConfig,
  DEFAULT_COMPILE_ENV,
};
