/**
 * 获取token
 */
export function getToken(): unknown {
  return localStorage.getItem('access_token');
}
export function setToken(token: string): void {
  console.log(token, 1010);
  localStorage.setItem('access_token', token);
}
/* 获取渠道channel
 */
export function getChannel(): unknown {
  return window.localStorage.getItem('channel');
}
export function setChannel(channel: string): void {
  window.localStorage.setItem('channel', channel);
}
/**
 * 获取缓存数据
 */
export function getStorage(name: string): unknown {
  return window.localStorage.getItem(name);
}
/**
 * 设置缓存数据
 */
export function setStorage(name: string, data: any): void {
  window.localStorage.setItem(name, data);
}
/**
 * 清除token
 */
export function removeStorage(name: string): void {
  localStorage.removeItem(name);
}

/**
 * 节流
 */
let pre = Date.now();
export const throttle = (fn: any, wait = 2000) => {
  const now = Date.now();
  if (now - pre >= wait) {
    fn.apply(this);
    pre = Date.now();
  }
};
